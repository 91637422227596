@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;700&display=swap');
@import url("./bootstrap.min.css");


body, p, input, textarea, select, button {
    font-family: 'Inter', sans-serif;
}

.btn {
    border-radius: 0 !important;
}

.quote-mark {
    font-size: 25rem;
    line-height: 0;
    left: 0;
    margin: 10rem -14rem;
    opacity: 0.2;
}

.bg-interns {
    background-image: url("../images/group_photo.b34d845b.jpg");
    background-position: center;
    background-size: cover;
}

.bg-interns-dark {
    background-color: rgba(0, 0, 0, 0.7);
    padding: 5rem;
}


.modal-open {
    overflow: hidden;
}

.modal-open main {
    opacity: 0.3;
}
